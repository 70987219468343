import * as React from "react";
import { Gallery } from "@patternfly/react-core";

import { LeaderboardCardComponent } from "./LeaderboardCardComponent";

export const LeaderboardContentComponent = ({ data, competition, startDate, endDate }) => {

  // const order = [1, 0, 2];

  return (
    <React.Fragment>
      <Gallery hasGutter minWidths={{ default: '100%' }}>
        {/* <Bullseye>
          <Flex spaceItems={{default: 'spaceItemsMd' }}>
          {
            data.slice(0, 3).map((user, index) => (
              <FlexItem key={index} order={{ default: `${order[index]}`}} alignSelf={{ default: 'alignSelfFlexEnd' }}>
                <LeaderboardTopThreeComponent key={user.user_id} index={index} user={user} competition={competition} startDate={startDate} endDate={endDate} />
              </FlexItem>
            ))
          }
          </Flex>
        </Bullseye> */}
        {
          data?.map((user, index) => (
            <LeaderboardCardComponent key={user.user_id} index={index} user={user} startDate={startDate} competition={competition} endDate={endDate} />
          ))
        }
      </Gallery>
    </React.Fragment>
  );

};
