import * as React from "react";
import { Alert, Button, DatePicker, Form, FormAlert, FormGroup, FormHelperText, HelperText, HelperTextItem, Modal, ModalVariant, Text, TextInput } from "@patternfly/react-core";

import { del, post, put } from "../../api/api";
import { NotificationContext } from "../../common/contexts/NotificationContext";
import { localLog } from "../../utils/Utilities";

export const CompetitionModal = ({ isOpen, onClose, competition, organizationId, action, onRefresh}) => {

  const { addNotification } = React.useContext(NotificationContext);

  const [debug] = React.useState(false);
  const [formData, setFormData] = React.useState(competition);
  const [formErrors, setFormErrors] = React.useState({});

  React.useEffect(() => {
    if (debug) localLog("competition: " + JSON.stringify(competition));
    setFormData(competition);
    setFormErrors({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [competition]);

  const dateFormat = (date/*: Date*/) => {
    return date.toLocaleDateString('en-ZA', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/\//g, '-');
  };

  const dateParse = (date/*: string*/) => {
    const split = date.split('-');
    if (split.length !== 3) {
      return new Date();
    }
    const year = split[0];
    const month = split[1];
    const day = split[2];
    return new Date(`${year.padStart(4, '0')}-${month.padStart(2, '0')}-${day.padStart(2, '0')}T00:00:00`);
  };

  const validateForm = () => {
    const err = {};
    if (!formData || !formData.period_name || formData.period_name === "") {
      err.period_name = "Competition Name is required";
    }
    console.log(formData);
    if (!formData || !formData.date_from || formData.date_from === "") {
      err.date_from = "Competition Start Date is required";
    }
    if (!formData || !formData.date_to || formData.date_to === "") {
      err.date_to = "Competition End Date is required";
    }
    if (!formData || !formData.date_from || !formData.date_to || dateParse(formData.date_from) > dateParse(formData.date_to)) {
      err.date_from = "Competition Start Date cannot be after the End Date";
    }
    setFormErrors(err);
    return Object.keys(err).length === 0;
  };

  const onDelete = async(e) => {
    try {
      const response = await del(organizationId, `/scoringPeriod/deleteScoringPeriod/${competition.id}`);
      if (debug) localLog("Response after Delete: " + JSON.stringify(response.data, null, 2));
      addNotification({ variant: 'success', title: 'Competition Deleted', description: 'The competition has been deleted successfully.' });
      onRefresh(response.data);
    } catch (error) {
      console.error(JSON.stringify(error));
      addNotification({ variant: 'error', title: 'Error Deleting Competition', description: 'An error occurred while trying to delete the competition.' });
    }
  };

  const onSave = async (e) => {
    // e.preventDefault();
    if (validateForm()) {
      try {
        if (competition) {
          // Update Competition
          const data = {
            "period_name": formData.period_name,
            "date_from": formData.date_from.substring(0, 10),
            "date_to": formData.date_to.substring(0, 10)
          }
          try {
            if (debug) localLog("data: " + JSON.stringify(data, null, 2));
            const response = await put(organizationId, `/scoringPeriod/updateScoringPeriod/${competition.id}`, data);
            if (debug) localLog("Response after Update: " + JSON.stringify(response.data, null, 2));
            addNotification({ variant: 'success', title: 'Competition Updated', description: 'The competition has been updated successfully.' });
            onRefresh(response.data);
          } catch (error) {
            console.error(JSON.stringify(error));
            addNotification({ variant: 'error', title: 'Error Updating Competition', description: 'An error occurred while trying to update the category.' });
          }
        } else {
          // Create Competition
          const form = new FormData();
          form.period_name = formData.period_name;
          form.organization_id = organizationId;
          form.date_from = formData.date_from;
          form.date_to = formData.date_to;
          try {
            if (debug) localLog("formdata: " + JSON.stringify(form));
            const response = await post(organizationId, `/scoringPeriod/createScoringPeriod`, JSON.stringify(form), { headers: { 'Content-Type': 'application/json' }});
            if (debug) localLog("Response after Create: " + JSON.stringify(response.data, null, 2));
            addNotification({ variant: 'success', title: 'Competition Created', description: 'The competition has been created successfully.'});
            onRefresh(response.data);
          } catch (error) {
            console.error(JSON.stringify(error));
            addNotification({ variant: 'error', title: 'Error Creating Competition', description: 'An error occurred while trying to create the competition.' });
          }
        }
        onClose();
      } catch (error) {
        console.error(JSON.stringify(error));
        addNotification({ variant: 'error', title: 'Error Occurred', description: 'An error occurred while trying to update the competition.' });
      }
    }
  };

  return (
    <Modal
      id="competition-modal"
      aria-label="Competition Modal"
      variant={ModalVariant.medium}
      title={action === "delete" ? "Delete Competition" : competition ? "Edit Competition" : "Add Competition"}
      isOpen={isOpen}
      onClose={onClose}
      actions={[
        <Button key="save" variant={action === "delete" ? "danger" : "primary"} form="competition-form" onClick={action === "delete" ? onDelete : onSave}>{action === "delete" ? "Delete" : "Save"}</Button>,
        <Button key="cancel" variant="secondary" onClick={onClose}>Cancel</Button>
      ]}
    >
      <Form id="competition-form" isHorizontal>
        {
          formErrors && Object.keys(formErrors).length > 0 && (
            <FormAlert>
              <Alert variant="danger" title="Fill out all required fields before continuing." isInline />
            </FormAlert>
          )
        }
        <FormGroup label="Name" isRequired fieldId="period_name">
          <TextInput id="period_name" isRequired type="text" value={formData?.period_name || ""} onChange={(e) => setFormData({...formData, period_name: e.target.value})}></TextInput>
          <FormHelperText>
            <HelperText>
              <HelperTextItem variant={formErrors?.period_name ? "error" : "default"}>
                <Text component="small">{ formErrors?.period_name }</Text>
              </HelperTextItem>
            </HelperText>
          </FormHelperText>
        </FormGroup>
        <FormGroup label="Date From" isRequired fieldId="date_from">
          <DatePicker value={formData?.date_from?.substring(0, 10)} dateFormat={dateFormat} dateParse={dateParse} requiredDateOptions={{ isRequired: true, emptyDateText: 'Date is required' }} appendTo={() => document.getElementById('competition-modal')} onChange={(_event, str, date) => setFormData({ ...formData, date_from: str })} />
          <FormHelperText>
            <HelperText>
              <HelperTextItem variant={ Object.keys(formErrors).length > 0 ? "error" : "default"}>
                <Text component="small">{ formErrors.date_from }</Text>
              </HelperTextItem>
            </HelperText>
          </FormHelperText>
        </FormGroup>
        <FormGroup label="Date To" isRequired fieldId="date_to">
          <DatePicker value={formData?.date_to?.substring(0, 10)} dateFormat={dateFormat} dateParse={dateParse} requiredDateOptions={{ isRequired: true, emptyDateText: 'Date is required' }} appendTo={() => document.getElementById('competition-modal')} onChange={(_event, str, date) => setFormData({ ...formData, date_to: str })} />
          <FormHelperText>
            <HelperText>
              <HelperTextItem variant={ Object.keys(formErrors).length > 0 ? "error" : "default"}>
                <Text component="small">{ formErrors.date_to }</Text>
              </HelperTextItem>
            </HelperText>
          </FormHelperText>
        </FormGroup>
      </Form>
    </Modal>
  );
};
