import * as React from "react";
import { useParams } from "react-router-dom";
import { ActionGroup, Badge, Breadcrumb, BreadcrumbItem, Button } from "@patternfly/react-core";
import { DataList, DataListItem, DataListItemRow, DataListItemCells, DataListCell } from "@patternfly/react-core";
import { Form, FormGroup, FormHelperText } from "@patternfly/react-core";
import { Gallery, GalleryItem } from "@patternfly/react-core";
import { HelperText, HelperTextItem, Icon } from "@patternfly/react-core";
import { PageBreadcrumb, PageGroup, PageSection, Panel, PanelMain, PanelMainBody } from "@patternfly/react-core";
import { Split, SplitItem, Stack, StackItem } from "@patternfly/react-core";
import { Tab, TabContent, TabContentBody, Tabs, TabTitleIcon, TabTitleText, Text, TextArea, TextContent } from "@patternfly/react-core";
import { CalendarIcon, CommentIcon, CommentsIcon, HomeIcon, OutlinedThumbsUpIcon } from "@patternfly/react-icons";

import { formatDistanceToNow } from "date-fns";
import { del, get, patch, post } from "../../api/api";
import { dynamicSort } from "../../utils/Utilities";
import { NotificationContext } from "../../common/contexts/NotificationContext";
import { OrganizationContext } from "../../common/contexts/OrganizationContext";
import { UserContext } from "../../common/contexts/UserContext";
import { AvatarComponent } from "../../components/AvatarComponent";
import { LoadingStateComponent } from "../../components/LoadingStateComponent";
import { NoResultsFoundComponent } from "../../components/NoResultsFoundComponent";
import { PageHeadingComponent } from "../../components/PageHeadingComponent";
import { ShoutOutCommentComponent } from "./ShoutOutCommentComponent";
import { ShoutOutModalCategoriesComponent } from "./ShoutOutModalCategoriesComponent";

export const ShoutOut = () => {

  const { addNotification } = React.useContext(NotificationContext);
  const organization = React.useContext(OrganizationContext);
  const user = React.useContext(UserContext);

  const { id } = useParams();

  // const [debug] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const [shoutout, setShoutout] = React.useState(undefined);
  const [comment, setComment] = React.useState("");
  const [comments, setComments] = React.useState([]);
  const [likes, setLikes] = React.useState([]);
  const [commentHelperText, setCommentHelperText] = React.useState("0/255 characters");
  const [activeTabKey, setActiveTabKey] = React.useState(0);

  const onTabClick = (event, tabIndex) => {
    setActiveTabKey(tabIndex);
  }

  const onEditComment = async (comment, newComment) => {
    const data = {
      shoutout_id: comment.shoutout_id,
      user_id: comment.user_id,
      comment_id: comment.id,
      comment: newComment
    }
    try {
      // eslint-disable-next-line no-unused-vars
      const response = await patch(organization.id, `/shoutoutInteraction/editComment`, data);
      refreshShoutOut();
      addNotification({ variant: 'success', title: 'Success', description: 'The comment was successfully edited.' });
    } catch (error) {
      console.error(JSON.stringify(error));
      addNotification({ variant: 'danger', title: 'Error Editing Comment', description: `${JSON.stringify(error?.message)}` });
    }
  };

  const onDeleteComment = async (comment) => {
    try {
      // eslint-disable-next-line no-unused-vars
      const response = await del(organization.id, `/shoutoutInteraction/${comment.shoutout_id}/comment/${comment.id}`);
      refreshShoutOut();
      addNotification({ variant: 'success', title: 'Success', description: 'The comment was successfully deleted.' });
    } catch (error) {
      console.error(JSON.stringify(error));
      addNotification({ variant: 'danger', title: 'Error Deleting Comment', description: `${JSON.stringify(error?.response)}`});
    }
  };

  const refreshShoutOut = async () => {
    try {
      setLoading(true);
      const response = await get(organization.id, "/shoutout/view/" + id );
      setShoutout(response.data)
      const sorted = response.data.shoutout_comments.sort(dynamicSort("-id"));
      setComments(sorted);
      const liked = response.data.shoutout_likes;
      setLikes(liked);
    } catch (error) {
      console.error(JSON.stringify(error));
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    refreshShoutOut();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCommentChange = (value) => {
    if (value.length > 0) {
      if (value.length === 1) {
        setComment(value);
        setCommentHelperText("1/255 character");
      } else {
        setComment(value);
        setCommentHelperText(`${value.length}/255 characters`);
      }
    } else {
      setComment(value);
      setCommentHelperText("0/255 characters");
    }
  };
    
  const handleCommentSubmit = async () => {
    const err = {};
    setErrors(err);
    if (comment.length === 0) {
      err.comment = "The comment is a required field.";
      setErrors(err);
      return;
    }
    if (comment.length < 11) {
      err.comment = "The comment should be at least 10 characters.";
      setErrors(err);
      return;
    }
    if (comment.length > 255) {
      err.comment = "The comment can only be 255 characters.";
      setErrors(err);
      return;
    }
    if (comment !== comment.trim()) {
      err.comment = "The comment cannot have leading or trailing spaces.";
      setErrors(err);
      return;
    }
    const body = {
      comment: comment,
      user_id: user.id,
      shoutout_id: shoutout.id,
      points: 10, // to be changed
    };
    try {
      // eslint-disable-next-line no-unused-vars
      const response = await post(organization.id, "/shoutoutInteraction/addComment", body);
      setComment("");
      refreshShoutOut();
      addNotification({ variant: 'success', title: 'Success', description: 'You added a comment to a ShoutOut.' });
    } catch (error) {
      console.error(JSON.stringify(error));
      addNotification({ variant: 'danger', title: 'Error Adding Comment', description: `${JSON.stringify(error?.message)}`});
    }
  };

  const CommentsContent = () => {
    return (
      <Gallery hasGutter minWidths={{ default: '100%' }}>
        {
          comments?.length > 0
          ? (
              <>
                {
                  comments?.map((comment, index) => (
                    <GalleryItem key={comment.id}>
                      <ShoutOutCommentComponent key={comment.id} comment={comment} onEdit={onEditComment} onDelete={onDeleteComment} />
                    </GalleryItem>
                  ))
                }
              </>
            )
          : ( <NoResultsFoundComponent isFullHeight icon={CommentsIcon} title="No Comments Found" message="No Comments were added on this ShoutOut." /> )
        }            
      </Gallery>
    );
  };

  const LikesContent = () => {
    return (
      likes.length === 0
      ? ( <NoResultsFoundComponent isFullHeight icon={OutlinedThumbsUpIcon} title="No Likes Found" message="No Likes were added on this ShoutOut." /> )
      : (
      <DataList aria-label="likes" isCompact>
        {
          likes.map((like, index) => (
            <DataListItem aria-labelledby={`like-${index}`} key={index}>
              <DataListItemRow>
                <DataListItemCells dataListCells={[
                  <DataListCell key="icon" isIcon>
                    <OutlinedThumbsUpIcon />
                  </DataListCell>,
                  <DataListCell key="avatar" isFilled={false}>
                    <AvatarComponent user={like.user} size="sm" />
                  </DataListCell>,
                  <DataListCell>
                    { like.user.first_name } { like.user.surname}
                  </DataListCell>,
                  <DataListCell>
                    <CalendarIcon />{ ' ' + like.date.slice(0, 10) + ' ' + like.date.slice(11, 19) }
                  </DataListCell>
                ]} />
              </DataListItemRow>
            </DataListItem>
          ))
        }
      </DataList>
      )
    );
  };

  return (
    <>
      <PageGroup stickyOnBreakpoint={{ default: 'top' }}>
        <PageBreadcrumb>
          <Breadcrumb>
            <BreadcrumbItem to="/">Dashboard</BreadcrumbItem>
            <BreadcrumbItem isActive>ShoutOut</BreadcrumbItem>
          </Breadcrumb>
        </PageBreadcrumb>
        <PageHeadingComponent title={"ShoutOut"} subtitle="Details of the ShoutOuts." icon={<Icon><HomeIcon /></Icon>} />
      </PageGroup>
      <PageSection isFilled>
      {
        loading
        ? ( <LoadingStateComponent /> )
        : (
          shoutout === undefined
          ? ( <NoResultsFoundComponent isFullHeight /> )
          : (
              <div className="pf-m-full-height">
                <Stack hasGutter>
                  <StackItem isFilled>
                    <Panel>
                      <PanelMain>
                        <PanelMainBody>
                          <Stack hasGutter>
                            <StackItem>
                              <Split hasGutter>
                                <SplitItem>
                                  <AvatarComponent user={shoutout?.toUser} size="lg" />
                                </SplitItem>
                                <SplitItem isFilled>
                                  <TextContent>
                                    <Text component="h1" style={{ marginBottom: '0px' }}>{`${shoutout?.toUser.first_name} ${shoutout?.toUser.surname}`}</Text>
                                    <Text component="h2" style={{ marginTop: '0px' }}>{shoutout?.toUser.user_role}</Text>
                                  </TextContent>
                                </SplitItem>
                                <SplitItem>
                                  <Stack>
                                    <StackItem>
                                      <TextContent>
                                        <Text>
                                          From:{" "} {shoutout?.external_shoutout_YN ? shoutout?.external_user_full_name : `${shoutout?.fromUser.first_name} ${shoutout?.fromUser.surname}`}
                                        </Text>
                                      </TextContent>
                                    </StackItem>
                                    <StackItem>
                                      { shoutout ? formatDistanceToNow(new Date(shoutout?.submitted_date), { addSuffix: true }) : "" }
                                    </StackItem>
                                  </Stack>
                                </SplitItem>
                              </Split>
                            </StackItem>
                            <StackItem>
                              <TextContent>
                                <Text component="p">{shoutout?.comment}</Text>
                              </TextContent>
                            </StackItem>
                            <StackItem>
                              <ShoutOutModalCategoriesComponent shoutout={shoutout} />
                            </StackItem>
                            <StackItem>
                              <Tabs id="shoutout-tabs" isBox usePageInsets activeKey={activeTabKey} onSelect={onTabClick}>
                                <Tab tabContentId={`tabContent0`} eventKey={0} title={<><TabTitleIcon><CommentIcon /></TabTitleIcon><TabTitleText>New Comment</TabTitleText></>} />
                                <Tab tabContentId={`tabContent1`} eventKey={1} title={<><TabTitleIcon><CommentsIcon /></TabTitleIcon><TabTitleText>Comments <Badge>{ comments?.length }</Badge></TabTitleText></>} />
                                <Tab tabContentId={`tabContent2`} eventKey={2} title={<><TabTitleIcon><OutlinedThumbsUpIcon /></TabTitleIcon><TabTitleText>Likes <Badge>{ likes?.length }</Badge></TabTitleText></>} />
                              </Tabs>
                            </StackItem>
                            <TabContent key={0} eventKey={0} id={`tabContent0`} activeKey={activeTabKey} hidden={0 !== activeTabKey}>
                              <TabContentBody hasPadding>
                                <Form>
                                  <FormGroup fieldId="comment" label="Comment" isRequired>
                                    <TextArea
                                      id="comment"
                                      aria-label="comment"
                                      autoFocus
                                      autoResize={true}
                                      isRequired
                                      placeholder="Write a comment..."
                                      resizeOrientation="none"
                                      rows={3}
                                      spellCheck={true}
                                      value={comment}
                                      onChange={(e) => onCommentChange(e.target.value)}
                                    />
                                    <FormHelperText>
                                      <HelperText style={{ float: 'right' }}>
                                        <HelperTextItem variant={'default'}>
                                          <Text component="small">{ commentHelperText }</Text>
                                        </HelperTextItem>
                                      </HelperText>
                                      <HelperText>
                                        <HelperTextItem variant={ Object.keys(errors).length > 0 ? "error" : "default"}>
                                          <Text component="small">{ errors.comment }</Text>
                                        </HelperTextItem>
                                      </HelperText>
                                    </FormHelperText>
                                  </FormGroup>
                                  <ActionGroup>
                                    <Button onClick={handleCommentSubmit}>Submit</Button>
                                  </ActionGroup>
                                </Form>
                              </TabContentBody>
                            </TabContent>
                            <TabContent key={1} eventKey={1} id={`tabContent1`} activeKey={activeTabKey} hidden={1 !== activeTabKey}>
                              <TabContentBody hasPadding>
                                <CommentsContent />
                            </TabContentBody>
                            </TabContent>
                            <TabContent key={2} eventKey={2} id={`tabContent2`} activeKey={activeTabKey} hidden={2 !== activeTabKey}>
                              <TabContentBody hasPadding>
                                <LikesContent />
                              </TabContentBody>
                            </TabContent>
                          </Stack>
                        </PanelMainBody>
                      </PanelMain>
                    </Panel>
                  </StackItem>
                </Stack>
              </div>
            )
          )
      }
      </PageSection>
    </>
  );
};
