import * as React from "react";
import { Breadcrumb, BreadcrumbItem, Button } from "@patternfly/react-core";
import { DataList, Icon, PageBreadcrumb, PageGroup, PageSection } from "@patternfly/react-core";
import { SearchInput, Toolbar, ToolbarContent, ToolbarGroup, ToolbarItem, Tooltip } from "@patternfly/react-core";
import { SyncIcon, UsersIcon } from "@patternfly/react-icons";

import { get } from "../../api/api";
import { OrganizationContext } from "../../common/contexts/OrganizationContext";
import { hasSuperAdminPermission, multipleSort, localLog } from "../../utils/Utilities";
import { ErrorStateComponent } from "../../components/ErrorStateComponent";
import { LoadingStateComponent } from "../../components/LoadingStateComponent";
import { PageHeadingComponent } from "../../components/PageHeadingComponent";
import { UserDataListItem } from "./UserDataListItem";
import { UserModal } from "./UserModal";

export const Users = ({ appUser }) => {

  const organization = React.useContext(OrganizationContext);

  const [debug] = React.useState(true);
  const [users, setUsers] = React.useState([]);
  const [user, setUser] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [isActionOpen, setIsActionOpen] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState(""); // State for search query

  const fetchUsers = async () => {
    setLoading(true);
    try {
      if (hasSuperAdminPermission(appUser)) {
        const response = await get(organization.id, "/users");
        if (debug) localLog("users: " + JSON.stringify(response, null, 2));
        setUsers(response.data.sort(multipleSort("first_name", "surname")));
      } else {
        const response = await get(organization.id, "/users/getAllUsersInOrganization?page=1&limit=200");
        if (debug) localLog("users: " + JSON.stringify(response, null, 2));
        setUsers(response.data.data.sort(multipleSort("first_name", "surname")));
      }
    } catch (error) {
      console.error(JSON.stringify(error));
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch users when the component mounts or the organization ID changes
  React.useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization.id]);

  const onActionSelect = () => {
    setIsActionOpen(0);
  };

  const onActionToggle = (id) => {
    if (isActionOpen === id)
      setIsActionOpen(0)
    else
      setIsActionOpen(id);
  };

  // const onAddUser = () => {
  //   setUser(null);
  //   onModalToggle();
  // };

  const onDeleteUser = (id) => {
    setUser(users.find(item => item.id === id));
    onModalToggle();
  };

  const onEditUser = (id) => {
    setUser(users.find(item => item.id === id));
    onModalToggle();
  };

  const onModalToggle = (e) => {
    setIsModalOpen(!isModalOpen);
  };

  const onRefreshUsers = () => {
    fetchUsers();
  };

  // Update the user list with the newly updated user data
  const handleUserUpdated = (updatedUser) => {
    // setUsers((prevUsers) =>
    //   prevUsers.map((user) => (user.id === updatedUser.id ? updatedUser : user))
    // );
    fetchUsers();
    onModalToggle();
  };

  // Filter users based on search query
  const filteredUsers = users?.filter(
    (user) =>
      user.first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.surname.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.email_address.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const renderToolbar = (
    <Toolbar>
      <ToolbarContent>
        <ToolbarGroup>
          <ToolbarItem variant="label">
            Users
          </ToolbarItem>
        </ToolbarGroup>
        <ToolbarGroup align={{default: 'alignRight' }}>
          <ToolbarItem variant="separator" />
          <ToolbarItem variant="search-filter">
            <SearchInput id="searchfilter" searchInputId="searchfilter" aria-label="search-filter" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
          </ToolbarItem>
        </ToolbarGroup>
        <ToolbarGroup>
          <ToolbarItem variant="separator" />
          {/*
          <ToolbarItem>
            <Tooltip content="Create new user">
              <Button icon={<PlusIcon />} variant="plain" onClick={onAddUser}></Button>
            </Tooltip>
          </ToolbarItem>
          */}
          <ToolbarItem>
            <Tooltip content="Refresh List">
              <Button icon={<SyncIcon />} variant="plain" onClick={onRefreshUsers}></Button>
            </Tooltip>
          </ToolbarItem>
        </ToolbarGroup>
      </ToolbarContent>
    </Toolbar>
  );

  return (
    <React.Fragment>
      <PageGroup stickyOnBreakpoint={{ default: 'top' }}>
        <PageBreadcrumb>
          <Breadcrumb>
            <BreadcrumbItem to="/">Dashboard</BreadcrumbItem>
            <BreadcrumbItem isActive>Administration</BreadcrumbItem>
            <BreadcrumbItem isActive>Users</BreadcrumbItem>
          </Breadcrumb>
        </PageBreadcrumb>
        <PageHeadingComponent title="Users" subtitle="Administer the users used in the application." icon={<Icon><UsersIcon /></Icon>} />
      </PageGroup>
      <PageSection>
        {
          loading
          ? <LoadingStateComponent />
          : (error !== null)
            ? <ErrorStateComponent error={error} />
            : <>
                { renderToolbar }
                <DataList isCompact>
                {
                  filteredUsers?.map((user) => (
                    <UserDataListItem 
                      key={user.id}
                      user={user}
                      isOpen={isActionOpen}
                      setIsOpen={setIsActionOpen}
                      onSelect={onActionSelect}
                      onToggle={onActionToggle}
                      onView={onEditUser}
                      onDelete={onDeleteUser}
                      onEdit={onEditUser}
                    />
                  ))
                }
                </DataList>
              </>
        }
      </PageSection>
      <UserModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} user={user} onUserUpdated={handleUserUpdated} organizationId={organization.id} />
    </React.Fragment>
  );
};
