import * as React from "react";
import { Button, DataListAction, DataListCell, DataListItem, DataListItemCells, DataListItemRow } from "@patternfly/react-core";
import { Dropdown, DropdownItem, DropdownList, MenuToggle } from "@patternfly/react-core";
import { ClipboardCheckIcon, EllipsisVIcon, EyeIcon } from "@patternfly/react-icons";

export const PermissionDataListItem = ({ permission, isOpen, setIsOpen, onSelect, onToggle, onEdit, onDelete, onView }) => {

  return (
    <DataListItem key={permission.id} aria-labelledby={permission.id}>
      <DataListItemRow>
        <DataListItemCells
          dataListCells={[
            <DataListCell key="icon" isIcon>
              <ClipboardCheckIcon />
            </DataListCell>,
            <DataListCell key="name">
              <Button size="sm" variant="link" isInline onClick={() => onView(permission?.id)}>{permission?.name}</Button>
            </DataListCell>,
            <DataListCell key="description">
              {permission.description}
            </DataListCell>
          ]}
        />
        <DataListAction isPlainButtonAction>
          <Dropdown
            popperProps={{ position: 'right' }}
            onOpenChange={(isOpen) => { if (isOpen) setIsOpen(permission.id); else setIsOpen(0) }}
            onSelect={ onSelect }
            isOpen={(isOpen === permission.id)}
            toggle={(toggleRef) => (
              <MenuToggle
                ref={toggleRef}
                isExpanded={isOpen === permission.id}
                onClick={() => onToggle(permission.id)}
                variant="plain"
                aria-label="Date List Item Action"
              >
                <EllipsisVIcon aria-hidden="true" />
              </MenuToggle>
            )}
          >
            <DropdownList>
              <DropdownItem key="view" icon={<EyeIcon />} onClick={() => onView(permission.id)}>View</DropdownItem>
{/*
              <DropdownItem key="edit" icon={<EditIcon />} onClick={() => onEdit(permission.id)}>Edit</DropdownItem>
              <Divider key="sep1"></Divider>
              <DropdownItem key="delete" icon={<TimesIcon />} onClick={() => onDelete(permission.id)}>Delete</DropdownItem>
*/}
            </DropdownList>
          </Dropdown>
        </DataListAction>
      </DataListItemRow>
    </DataListItem>
  );

};
