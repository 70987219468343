import * as React from "react";
import { Breadcrumb, BreadcrumbItem, Button } from "@patternfly/react-core";
import { Icon } from "@patternfly/react-core";
import { PageBreadcrumb, PageGroup, PageSection, PageSectionVariants } from "@patternfly/react-core";
import { Toolbar, ToolbarContent, ToolbarGroup, ToolbarItem, Tooltip } from "@patternfly/react-core";
import { DownloadIcon, SyncIcon, TrophyIcon } from "@patternfly/react-icons";

import { CompetitionToolbarComponent } from "../components/CompetitionToolbarComponent";
import { NoResultsFoundComponent } from "../components/NoResultsFoundComponent";
import { PageHeadingComponent } from "../components/PageHeadingComponent";

import { get } from "../api/api";
import { LeaderboardContentComponent } from "../components/LeaderboardContentComponent";
// import MobileLeaderboard from "../components/leaderboard/MobileLeaderboard";
import { NotificationContext } from "../common/contexts/NotificationContext";
import { OrganizationContext } from "../common/contexts/OrganizationContext";
// import UserStats from "../components/leaderboard/UserStats";
import { ErrorStateComponent } from "../components/ErrorStateComponent";
import { LoadingStateComponent } from "../components/LoadingStateComponent";
import { hasReportsPermission /*, localLog */ } from "../utils/Utilities";

import * as XLSX from "xlsx";

export const Leaderboard = ({ user }) => {

  const { addNotification } = React.useContext(NotificationContext);
  const organization = React.useContext(OrganizationContext);

  // const [userList, setUserList] = React.useState([]);
  // const [searchQuery, setSearchQuery] = React.useState("");
  // const [selectedUser, setSelectedUser] = React.useState(null);

//  const { isOpen, onOpen, onClose } = useDisclosure();

 // Format date
 const formatDate = (date) => {
   const year = date.getFullYear();
   const month = String(date.getMonth() + 1).padStart(2, "0");
   const day = String(date.getDate()).padStart(2, "0");
   return `${year}-${month}-${day}`;
 };

  const today = new Date();

  //Set defaults dates for date pickers
  const startDateDefault = new Date(
    today.getFullYear(),
    today.getMonth() - 1,
    today.getDate()
  );

  const endDateDefault = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );

  const [error, setError] = React.useState(null);
  const [debug] = React.useState(true);
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [startDate, setStartDate] = React.useState(startDateDefault);
  const [endDate, setEndDate] = React.useState(endDateDefault);
  const [competition, setCompetition] = React.useState(undefined);

  const onDownloadReport2 = async () => {
    // const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };

    await get(organization.id, `/shoutout/getLeaderboard?startDate=${formatDate(startDate)} 00:00:00&endDate=${formatDate(endDate)} 23:59:59&organization_id=${organization.id}`, requestOptions)
    .then((res) => {
      if (res.data.length === 0) {
        addNotification({ variant: 'danger', title: 'Error', description: 'No Data Found.  Report is empty.' });
        return;
      }
      const data2 = res.data.map(item => ({
        "ID": item.user_id,
        "Firstname": item.firstname,
        "Surname": item.surname,
        "Picture": item.profile_picture_url,
        "ShoutOuts": item.no_shoutouts,
        "CategoryPoints": item.category_points,
        "LikePoints": item.like_points,
        "CommentPoints": item.comment_points,
        "TotalPoints": item.total_points,
        "TotalLikes": item.total_likes,
        "TotalComments": item.total_comments,
      }));
      const ws = XLSX.utils.json_to_sheet(data2);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Leaderboard");
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: 'array' });
      const href = window.URL.createObjectURL(new Blob([excelBuffer], { type: 'application/octet-stream' /*fileType*/ }));
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', `Leaderboard ${formatDate(startDate)} - ${formatDate(endDate)}${fileExtension}`);  // or any other extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((err) => {
      return Promise.reject({ Error: 'Something Went Wrong', err });
    })

  };

  // const fetchCompetitions = async () => {
  //   setLoading(true);
  //   try {
  //     const response = await get(organization.id, `/scoringPeriod/getAllScoringPeriods`);
  //     if (debug) localLog("response: " + JSON.stringify(response, null, 2));
  //     setCompetitions(response.data.scoringPeriods);
  //     setLoading(false);
  //   } catch (error) {
  //     if (error?.status === 404) {
  //       setCompetitions([]);
  //       setLoading(false);
  //     } else {
  //       console.error(JSON.stringify(error));
  //       setLoading(false);
  //     }
  //   }
  // };

  React.useEffect(() => {

    const firstFetch = async() => {
      if (debug)
        console.log("firstFetch()");
      // fetchCompetitions();
    }

    if (debug)
      console.log("useEffect []");
    firstFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async (start, end) => {
    if (debug)
      console.log("fetchData()");
    setLoading(true);
    try {
      if (debug) console.log(` endpoint: /shoutout/getLeaderboard?startDate=${formatDate(start)} 00:00:00&endDate=${formatDate(end)} 23:59:59&organization_id=${organization.id}`);
      const response = await get(organization.id, `/shoutout/getLeaderboard?startDate=${formatDate(start)} 00:00:00&endDate=${formatDate(end)} 23:59:59&organization_id=${organization.id}`);
      if (debug) console.log("response.data: " + JSON.stringify(response.data, null, 2));
      setData(response.data);
    } catch (error) {
      console.error(JSON.stringify(error));
      setError("There was an error fetching the data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // React.useEffect(() => {
  //   if (debug)
  //     console.log("useEffect [startDate]");
  //   fetchData(startDate, endDate);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [startDate]);

  React.useEffect(() => {
    if (debug)
      console.log("useEffect [endDate]");
    fetchData(startDate, endDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDate]);

  const onRefresh = () => {
    if (debug)
      console.log("onRefresh()");
    fetchData(startDate, endDate);
  };

//  const handleSearchInputChange = (event) => {
//    setSearchQuery(event.target.value);
//  };

//  const handleUserSelect = (user) => {
//    setSelectedUser(user);
//    setSearchQuery("");
//    onOpen();
//  };

  // Function to handle search cancellation
//  const handleCancelSearch = () => {
//    setSearchQuery("");
//    setUserList([]);
//  };

  // const toValidator = (date) => {
  //   return isValidDate(startDate) && date >= startDate ? '' : 'The End Date must be after the Start Date'
  // }

  // const onStartDateChange = (_event, _value, _date) => {
  //   if (isValidDate(_date) && _value === yyyyMMddFormat(_date)) {
  //     setStartDate(_date);
  //     setEndDate(new Date(_date.getFullYear(), _date.getMonth() + 1, _date.getDate()));
  //   }
  // };

  // const onEndDateChange = (_event, _value, _date) => {
  //   if (isValidDate(_date) && _value === yyyyMMddFormat(_date)) {
  //     setEndDate(_date);
  //   }
  // };

  return (
    <>
      <PageGroup stickyOnBreakpoint={{ default: 'top' }}>
        <PageBreadcrumb>
          <Breadcrumb>
            <BreadcrumbItem to="/">Dashboard</BreadcrumbItem>
            <BreadcrumbItem isActive>Leaderboard</BreadcrumbItem>
          </Breadcrumb>
        </PageBreadcrumb>
        <PageHeadingComponent title="Leaderboard" subtitle="Show the leaderboard for a time period." icon={<Icon><TrophyIcon /></Icon>} />
        <PageSection variant={PageSectionVariants.light} padding={{ default: 'noPadding' }} >
          <Toolbar>
            <ToolbarContent>
              <CompetitionToolbarComponent organizationId={organization.id} competition={competition} setCompetition={setCompetition} startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />
{/*
              <ToolbarGroup>
                <ToolbarItem variant="label">
                  Start Date
                </ToolbarItem>
                <ToolbarItem>
                  <DatePicker aria-label="Start Date" placeholder="YYYY-MM-DD" value={yyyyMMddFormat(startDate)} onChange={onStartDateChange} requiredDateOptions={{ isRequired: true, emptyDateText: "Date is required" }} />
                </ToolbarItem>
              </ToolbarGroup>
              <ToolbarGroup>
                <ToolbarItem variant="label">
                  End Date
                </ToolbarItem>
                <ToolbarItem>
                  <DatePicker aria-label="End Date" invalidFormatText="Invalid Format" placeholder="YYYY-MM-DD" rangeStart={startDate} value={yyyyMMddFormat(endDate)} onChange={onEndDateChange} validators={[toValidator]} isDisabled={!isValidDate(startDate)} />
                </ToolbarItem>
              </ToolbarGroup>
*/}
{/*
              <ToolbarGroup>
              <ToolbarItem variant="label">
                Search for User
              </ToolbarItem>
              <ToolbarItem>
                <Input placeholder="Search for user" value={searchQuery} onChange={handleSearchInputChange} width="100%" maxWidth="200px" padding="2px" marginBottom="2px" />
                {
                  userList.length > 0 && (
                    <Box width="100%" maxWidth="520px" position="absolute" backgroundColor="white" border="1px solid gray" marginTop="2px" borderRadius="2px" zIndex="1">
                      {
                        userList.map((user) => (
                          <Box key={user.id} onClick={() => handleUserSelect(user)} cursor="pointer" _hover={{ bg: "gray.100" }} py={2} px={3} borderBottom="1px solid lightgray" display="flex" alignItems="center">
                            <Avatar name={`${user.first_name} ${user.surname}`} src={user.profile_picture_url} size="sm" mr={3} />
                            <Box>
                              <Text fontWeight="bold">{user.first_name} {user.surname}</Text>
                              <Text color="gray.600">{user.email_address}</Text>
                            </Box>
                          </Box>
                        ))
                      }
                      <Button onClick={handleCancelSearch} width="100%">
                        Cancel
                      </Button>
                    </Box>
                  )
                }
              </ToolbarItem>
            </ToolbarGroup>
*/}
              <ToolbarGroup align={{default: 'alignLeft' }}>
                <ToolbarItem variant="separator" />
                <ToolbarItem>
                  <Tooltip content="Refresh">
                    <Button variant="plain" onClick={onRefresh}><SyncIcon /></Button>
                  </Tooltip>
                </ToolbarItem>
                {
                  hasReportsPermission(user)
                  ? (
                      <ToolbarItem>
                        <Tooltip content="Download Report">
                          <Button variant="plain" onClick={onDownloadReport2}><DownloadIcon /></Button>
                        </Tooltip>
                      </ToolbarItem>
                  )
                  : null
                }
              </ToolbarGroup>
            </ToolbarContent>
          </Toolbar>
        </PageSection>
      </PageGroup>
      <PageSection>
        {
          loading ?
          ( <LoadingStateComponent /> ) :
          ( error ?
            ( <ErrorStateComponent error={error} /> ) :
            ( data?.length === 0 ?
              ( <NoResultsFoundComponent /> ) :
              ( <LeaderboardContentComponent data={data} competition={competition} startDate={formatDate(startDate)} endDate={formatDate(endDate)} /> )
            )
          )
        }
      </PageSection>
    </>
  );

};
